var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"1200px","showFooter":false,"title":"文件处理履历"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('base-form',{ref:"formData",staticClass:"formStyle",attrs:{"componentList":_vm.formConfig,"formAttrs":{
      model: _vm.queryParas,
    }},on:{"handleFilter":_vm.handleFilter,"clearParams":_vm.clearParams}}),_c('base-table',{ref:"tableData",attrs:{"columns":_vm.columns,"showPage":true,"tableAttrs":{
      data:_vm.tableData,
      stripe: false,
      border: true,
      height: 400,
      spanMethod: _vm.objectSpanMethod,
    },"webPage":true,"isCaculateHeight":false},on:{"pageIndexChange":_vm.pageIndexChange,"pageSizeChange":_vm.pageSizeChange},scopedSlots:_vm._u([{key:"index",fn:function(scope){return [_vm._v(_vm._s(scope.$index + 1))]}},{key:"action",fn:function(scope){return [_c('icon-button',{attrs:{"content":"预览","icon":"iconfont iconyulan"},on:{"click":function($event){return _vm.preViewFile(scope.row)}}}),_c('icon-button',{attrs:{"content":"下载","icon":"iconfont iconxiazai"},on:{"click":function($event){return _vm.dowloadFile(scope.row.fileId)}}})]}}])}),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"label":"关 闭","type":"default"},on:{"click":function($event){_vm.dialogVisible = false}}})],1),_c('pre-view',{attrs:{"fileId":_vm.fileId,"isOpen":true,"fileType":_vm.fileType,"count":_vm.preViewcount}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }